import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

function Index() {
  return (
    <Layout>
      <Seo title="Under Construction" />
      <div className="mx-auto text-center text-lg">
        This website is currently under construction
      </div>
    </Layout>
  );
}

export default Index;
